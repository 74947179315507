<template>
<v-container>
  <!-- BOC:[breadcrumbs] -->
  <ABreadcrumbV1 :items="breadcrumbs"></ABreadcrumbV1>
  <!-- EOC -->
  <!-- BOC:[form] -->
<PageLink :url="`${$api.servers.sso}/api/v1/en/moderator/student/linkWithPlayer`" :self="self" :callbackSuccess="callbackSuccess" />
</v-container>
</template>

<script>
  //BOC:[model]
  import modelGrandParent from '@/models/items/institution'
  import modelParent from '@/models/items/classroom'
  import model from '@/models/items/student'
  //EOC
  import { mapState } from 'vuex'
import PageLink from '../../components/Moderator/Student/Link/PageLink.vue';
  export default {
    components:{
      PageLink,
    },
    computed: mapState({
      school: state => state.school.data,
    }),
    props:[
    'parent',
      'self',
    ],
    data: () => ({
      //BOC:[breadcrumbs]
      breadcrumbs: [],
      //EOC
      //BOC:[model]
      modelGrandParent: modelGrandParent,
      modelParent: modelParent,
      model: model,
      //EOC
      //BOC:[role]
      role:'Moderator',
      //EOC
       //BOC:[parent]
       myParent:{
        id:null,
      },
      //EOC
      //BOC:[self]
      me:{
        id:null,
      },
      //EOC
    }),
    created() {
       //BOC:[parent]
       if(this.parent) this.myParent = this.$_.cloneDeep(this.parent)
      //EOC
      //BOC:[self]
      if(this.self) this.me = this.$_.cloneDeep(this.self)
      //EOC
      //BOC:[breadcrumbs]
      this.breadcrumbs.push({
        text:this.$t("model.name.dashboard"),
        to:{name:'Page'+this.role+'Dashboard'},
        exact:true,
      })
       //
       this.breadcrumbs.push({
        text:this.$t("model.name." + this.modelGrandParent.name.plural.toLowerCase()),
        to:this.$_getRouteBrowse(this.role,this.modelGrandParent.key),
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:(this.grandParent && this.grandParent.name) ? this.grandParent.name : `${this.$t("model.name." + this.modelGrandParent.name.singular.toLowerCase())} ${this.$route.params.grandParentId}`,
        to:this.$_getRouteRead(this.role,this.modelGrandParent.key,this.$route.params.grandParentId,this.grandParent),
        exact:true,
      })
      let grandParentPath = this.$_.cloneDeep(this.breadcrumbs[this.breadcrumbs.length-1].to)
       grandParentPath.query = {tab:'Classroom'}
      this.breadcrumbs.push({
        text:this.$t("model.name." + this.modelParent.name.plural.toLowerCase()),
        to:grandParentPath,
        exact:true,
      })
      let path = this.$_.cloneDeep(this.breadcrumbs[this.breadcrumbs.length-1].to)
      path.query = {tab:'Student'}
      this.breadcrumbs.push({
        text:this.$t("model.name." + this.model.name.plural.toLowerCase()),
        to:path,
        exact:true,
      })
      this.breadcrumbs.push({
        text:(this.me.name) ? this.me.name : `${this.$t("model.name." + this.model.name.singular.toLowerCase())} ${this.$route.params.id}`,
        to:this.$_getRouteGrandChildRead(this.role,this.model.key,this.$route.params.id,this.self,this.modelParent.key,this.$route.params.parentId,this.myParent,this.modelGrandParent.key,this.$route.params.grandParentId,this.myGrandParent),
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:`Link`,
        to:{name:'PageModeratorInstitutionClassroomStudentLink'},
        exact:true,
      })
      //EOC
    },
    mounted() {
      //
    },
    methods: {
      callbackSuccess() {
        let redirect = this.$_getRouteChildRead(this.role,this.modelParent.key,this.$route.params.parentId,this.myParent, this.modelGrandParent.key,this.$route.params.grandParentId,this.grandParent)
        redirect.query = {tab:this.model.key}
        this.$router.push(redirect)
      },
    }
  }
</script>